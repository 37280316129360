const en = {
  translation: {
    selectLanguage: "Select Language",
    saveButton: "Save",
    footerText:
      "Enatega is an open-source delivery management platform for the future. We prioritize innovation, flexibility, and affordability, and offer a scalable, customizable solution that streamlines your delivery processes.",
    footerLinkHome: "Home",
    footerLinkPP: "Privacy Policy",
    footerLinkTC: "Terms & Conditions",
    footerEndText: "Enatega – © 2022 All Rights Reserved",
    followUs: "Follow Us",
    poweredBy: "Powered By",
    linksTitle: "Links",
    subTotal: "SubTotal",
    deliveryFee: "Delivery Fee",
    haveVoucher: "Do you have a voucher ?",
    remove: "Remove",
    change: "Change",
    taxFee: "Tax Charges",
    total: "Total",
    tip: "Tip",
    discount: "Discount",
    name: "Name",
    phone: "Phone",
    email: "Email",
    address: "Address",
    changeAddress: "Change Address",
    contactInfo: "Contact Information",
    paymentMethod: "Payment Method",
    orderBtn: "Place order",
    deliveryTime: "Delivery Time",
    delivery: "Delivery",
    searchRestaurantPlaceholder: "Search for restaurant and cuisines",
    allRestaurant: "All Restaurants",
    pending: "PENDING",
    accepted: "ACCEPTED",
    assigned: "ASSIGNED",
    picked: "PICKED",
    delivered: "DELIVERED",
    completed: "COMPLETED",
    titleOrders: "My Orders",
    titleProfile: "Profile",
    titleSettings: "Settings",
    titleHelp: "Help",
    titleChat: "Chat",
    titleLogout: "Logout",
    passwordInfo: "Password Info",
    myAddresses: "My Addresses",
    edit: "Edit",
    creditDebitCard: "Credit / Debit Card",
    paypal: "Paypal",
    cash: "Cash",
    deliverTo: "Deliver to",
    ASSIGNED: "ASSIGNED",
    PENDING: "PENDING",
    PICKED: "PICKED",
    ACCEPTED: "ACCEPTED",
    DELIVERED: "DELIVERED",
    COMPLETED: "COMPLETED",
    orderPlaced: "Order Placed",
    "Order ID": "Order ID",
    "Order status: PENDING": "Order status: PENDING",
    "Order status: ACCEPTED": "Order status: ACCEPTED",
    "Order status: ASSIGNED": "Order status: ASSIGNED",
    "Order status: PICKED": "Order status: PICKED",
    "Order status: DELIVERED": "Order status: DELIVERED",
    "Order status: Order Placed": "Order status: Order Placed",
    deliveryAddress: "Delivery Address",
    orderDetail: "Order Detail",
    orderFrom: "Your Order From",
    orderNo: "Your Order No.",
    findRestaurants: "Find Restaurants",
    putUsInYourPocket: "Put us in your pocket",
    containerText:
      "It's all at your fingertips -- the restaurants you love. Find the right food to suit your mood, and make the first bite last. Go ahead, download us.",
    findingAddress: "Finding address using GPS integration",
    zonesFunctionality: "Zones functionality for Restaurants and Riders",
    multipleRestaurants: "Multiple Restaurant  adding feature",
    realTimeOrder: "Real-time order receiving updates",
    differentSections: "Different sections feature for promoting restaurants",
    previousOrder: "Previous order history and adding favorite restaurants",
    ourDelivery: "Our delivery management system is designed for the future.",
    builtOnCommunity: "Built on community-driven principles.",
    yourCart: "Your Cart",
    startAdding: "Start adding items to your cart",
    goToCheckout: "GO TO CHECKOUT",
    activeOrders: "ACTIVE ORDERS",
    pastOrders: "PAST ORDERS",
    deliveringTo: "DELIVERING TO",
    restaurantDeliver:
      "We are asking the restaurant how long it will take to deliver your food",
    riderDeliver:
      "We are asking the rider how long it will take to deliver your food",
    orderPending: "Your order is still pending",
    areYouSure: "Are you sure?",
    clearCartText:
      "By changing restaurant, the items you`ve added to cart will be cleared",
    ok: "OK",
    cancel: "CANCEL",
    submit: "Submit",
    titleFavourite: "Favourites",
    titleEmptyFav: "No Favourites Saved",
    emptyFavDesc:
      "You'll see all your favourites here, to make ordering even faster.",
    emptyFavBtn: "Let's find some favourites",
    exactLocation: "Is this your exact location?",
    yourArea: "Enter Your Area",
    currentLocation: "Use current location",
    deliveryDetails: "Required details -e.g. floor/ building",
    deliveryDetailError: "Delivery details is required",
    currentPassword: "Current Password",
    newPassword: "New Password",
    deliver: "Delivery",
    time: "Time",
    pickup: "Pickup",
    selectTime: "Select Time",
    am: "AM",
    pm: "PM",
    set: "Set",
    apply: "Apply",
    applyAVoucher: "Apply a voucher",
    readAll: "Read All",
    readMore: "Read More",
    demo: "Demo",
    getQuote: "Get Quote",
    restaurantCloseText:
      "The restaurant is closed right now. Check out others that are open or take a look at the menu to plan for your next meal.",
    seeOtherRestaurants: "SEE OTHER RESTAURANTS",
    goToMenu: "Go To Menu",
    deliveryHours: "Delivery hours",
    about: "About",
    reviews: "REVIEWS",
    closedAllDay: "Closed all day",
    minute: "Minute",
    welcome: "Welcome!",
    signUpOrLogin: "Sign up or log in to continue",
    signInWithGoogle: "CONTINUE WITH GOOGLE",
    or: "or",
    continueWithEmail: "CONTINUE WITH EMAIL",
    terms: "Terms and Conditions",
    privacyPolicy: "Privacy Policy",
    bySigningUp: "By signing up, you agree to our ",
    and: " and ",
    whatsYourEmail: "What's your Email?",
    checkAccount: "We'll check if you've an account",
    continue: "Continue",
    invalidEmail: "Invalid Email",
    letsGetStarted: "Let's get started!",
    createAccount: "Create your Enatega Account",
    firstnameErr1: "Firstname is required!",
    firstnameErr2: "Invalid First Name!",
    lastnameErr1: "Lastname is required!",
    lastnameErr2: "Invalid Last Name!",
    mobileErr1: "Phone number is required!",
    mobileErr2: "Invalid Phone number",
    emailErr2: "Invalid Email",
    passwordErr1:
      "Invalid Password. Password must contain at least 1 capital letter, 1 small letter, and 1 number.",
    passwordErr2: "Invalid Password",
    generalErr: "Something is missing",
    verifyEmail: "Verify your email",
    enterOtp: "Please enter the OTP we sent to your updated email",
    enterOtpPhone: "Please enter the OTP we sent to your updated Phone Number",
    invalidCode: " Invalid code, please check and enter again",
    resendCode: "Resend code",
    retryAfter: "Retry after",
    loginBtn: "Login",
    addToCart: "Add to Cart",
    customize: "Customize",
    optional: "OPTIONAL",
    required: "REQUIRED",
    specialInstructions: "Special Instructions",
    anySpecific: "Any specific preferances? Let the restaurant know.",
    selectVariation: "Select Variation",
    closed: "Closed",
    new: "NEW",
    orderCancelled: "Your order has been cancelled",
    orderCompleted: "Order completed successfully. Thankyou for placing order",
    reorder: "REORDER",
    review: "Review",
    subTitle: "Select 1",
    placeholder: "e.g. No mayo",
    phoneNumMissing: "Phone Number is missing",
    updatePhone: "Update your phone",
    number: "number?",
    secureAcc: "We need this to secure your account",
    verifyPhone: "Verify your phone",
    skipNow: "Skip now",
    favouriteListUpdated: "Favourite list updated",
    pendingText: "Waiting response from",
    acceptedText: "Estimated preparation time",
    orderLateText: "Sorry! Your order is bit late.",
    riderPickText: "Your rider will pick it up once its ready",
    orderIs: "Your order is",
    orderAssigned: "assigned to the rider",
    orderAssignedToRider:
      "Your order is assigned to our rider, order will be picked once its ready.",
    riderOnWay: "Your rider is on the way.",
    orderHasBeen: "Your order has been",
    enjoyYourMeal: "Enjoy your meal!",
    cancelled: "Cancelled",
    personalDetail: "Personal Detail",
    voucherCode: "Voucher code",
    pickUp: "Pickup",
    warning: "warning",
    warningText: "One or more item is not available",
    "Rider App": "Rider App",
    FEATURES: "FEATURES",
    "Restaurant App": "Restaurant App",
    "Customer App": "Customer App",
    "Admin Dashboard": "Admin Dashboard",
    "Product Page": "Product Page",
    PlayStore: "Play Store",
    IosStore: "Ios Store",
    APP: "APP",
    Enatega: "Enatega",
    connectText:
      "Connecting Riders, Restaurants, and Customers for Seamless Food Delivery",
    connectSubText:
      "Join the platform that delivers convenience, flexibility, and growth for everyone involved. Whether you’re a Rider looking to earn, a Restaurant aiming to expand, or a Customer craving delicious meals, we’ve got you covered!",
    PlatformText: "Why Join Our Platform?",
    PlatformSubtext:
      "We’re not just a food delivery platform – we’re a community built to benefit everyone. Here’s why thousands of Riders, Restaurants, and Customers trust us:",
    ForRider: "For Riders:",
    platformRiderText:
      " Earn on your schedule, get quick payouts, and enjoy easy-to-use tools for managing deliveries.",
    ForRestaurant: "For Restaurants:",
    platformRestaurantText:
      " Boost your reach, streamline order management, and grow your revenue with real-time analytics.",
    ForCustomer: "For Customers:",
    platformCustomerText:
      " Enjoy a wide range of restaurants, fast delivery, and a user-friendly app and website.",
    orderText: "Order Your Favorites Anytime, Anywhere",
    orderSubText1:
      "Experience the convenience of having all your favorite restaurant meals and cuisines in one place, delivered straight to your door—fast and fresh. Download the Enatega App today, and turn every craving into a delicious reality.",
    orderSubText2:
      "Explore a wide range of options, from local favorites and comfort food to gourmet dishes, healthy eats, and more.",
    orderSubText3:
      "Download the Enatega App today, and turn every craving into a delicious reality.",
    platformFeatureText: "Platform Features That Make Us Stand Out.",
    platformFeatureSubText:
      "We provide cutting-edge features to ensure your experience is smooth and hassle-free:",
    listReal: "Real-Time Order Tracking:",
    listRealText: " Know exactly where your food is.",
    securePayment: " Secure Payments:",
    securePaymentText: " For Riders, Restaurants, and Customers.",
    twoFour: "24/7 Support:",
    twoFourText: " Always here to assist you.",
    custMenu: "Customizable Menus:",
    custMenuText: " Restaurants can manage offerings effortlessly.",
    BecomeRider: " Become a Rider and Start Earning Today!",
    earnMore: "Earn More:",
    earnMoreText: " Deliver when you want and earn great payouts.",
    flexible: "Flexible Hours:",
    flexibleText: " Work according to your schedule.",
    intsaPay: "Instant Payments:",
    intsaPayText: " Get paid weekly or instantly.",
    growRestuarant: "Grow Your Restaurant with Our Platform",
    reactCust: "Reach More Customers:",
    reactCustText: " Get discovered by local food lovers.",
    easyOrder: "Easy Order Management:",
    easyOrderText: " Handle everything in one dashboard.",
    incrRevenue: "Increase Revenue:",
    incrRevenueText: " Attract more orders and grow your business.",
    OrderFood: " Order Food Anytime, Anywhere!",
    expMenu: "Explore Menus:",
    expMenuText: " Find your favorite cuisines.",
    track: "Track Orders:",
    trackText: " See where your food is in real time.",
    mulPlat: "Multiple Platforms:",
    mulPlatText: " Order via mobile app or web app.",
    Register: "Register –",
    RegisterText: " List your restaurant easily.",
    recieveOrders: "Receive Orders –",
    recieveOrdersText: " Manage them with our user-friendly dashboard.",
    grow: "Grow –",
    growText: " Attract more customers and boost sales.",
    signUp: "Sign Up –",
    signUpText: " Create a profile in minutes.",
    accOrders: "Accept Orders –",
    accOrderText: " Deliver food at your convenience.",
    earnMoney: "Earn Money –",
    earnMoneyText: " Get paid weekly or instantly.",
    brMenu: "Browse Menus –",
    brMenuText: " Find your favorite food nearby.",
    placeOrder: "Place an Order –",
    placeOrderText: " Enjoy fast delivery.",
    relax: "Relax –",
    relaxText: " Let us handle the rest!",
    contactUs: "Contact Us",
    vendors: "Vendors",
    drivers: "Drivers",
    becomeVendor: "Become a Vendor",
    becomeRestaurant: "Become a Restaurant",
    getToKnow: "Get to Know",
    letUsHelp: "Let Us Help You",
    howItWorks: "How It Works for Everyone",
    registerRestaurant: "Register Your Restaurant",
    signUpRider: "Sign Up for a Rider",
    orderFoodNow: "Order Food Now",
    signupDelivering: "Sign Up to Start Delivering",

    riderHeading: "Ready when you are...",
    riderDescription: "Better deliveries. Better customer experiences.",
    vendorHeading: "A Delivery Solution Designed to Grow your Restaurant",
    vendorDescription:
      "Expand your reach, attract more customers, and build stronger connections in every neighborhood. Join Enatega as a vendor today!",
    contactUsHeading: "How would you like to get help?",
    contactUsDescription: "Choose the option that is convenient for you",
    emailConfirmation: "Email Sent Successfully!",
    welcomeText: "Welcome to Enatega",
    emailConfirmationDescription:
      "We are thrilled to have you join us as we aim to make delivery better for everyone. As a delivery/pickup partner, you get to work according to your schedule anywhere, anytime. Need Help? send us an email at enatega.nb@gmail.com",
    getStarted: "Get Started",
    thankyouPageHeading: "Your email has been sent successfully!",
    thankyouPageDescription:
      "A member of our team will reach out to you shortly to help you get set-up and answer any questions you might have. Need Help? send us an email at enatega.nb@gmail.com",
    getStartedToday: "Get Started Today!",
    termsRequired: "You must accept the terms and conditions.",
    passNotMatch: "Passwords do not match.",
    formSubmission: "Form Submitted Successfully",
    notFormSubmission: "Form Not Submitted",
    firstName: "First Name",
    lastName: "Last Name",
    emailAddress: "Email Address",
    phoneNumber: "Enter your phone number",
    password: "Password",
    confirmPassword: "Confirm Password",
    viaPhone: "Via Phone",
    vieEmail: "Via Email",
    viaPhoneDesctiption: "Confirm your phone number and we'll give you a call",
    viaEmailDesctiption: "Confirm your email address and we'll reply to you",
    explainQuery: "Briefly explain your query...",
    message: "message",
    enterValidEmail: "Enter a valid email",
    requiredEmail: 'Email is required',
    enterValidPhone: 'Enter a valid phone number',
    'I agree to the': 'I agree to the ',
  },
};
export default en;
