const he = {
  translation: {
    selectLanguage: "בחר שפה",
    saveButton: "שמור",
    footerText:
      "יאללה היא פלטפורמת ניהול משלוחים בקוד פתוח לעתיד. אנו מעדיפים חדשנות, גמישות ונגישות כלכלית, ומציעים פתרון ניתן להתאמה אישית וגמיש שמקל על תהליכי המשלוח שלך.",
    footerLinkHome: "בית",
    footerLinkPP: "מדיניות פרטיות",
    footerLinkTC: "תנאים והגבלות",
    footerEndText: "יאללה - © 2022 כל הזכויות שמורות",
    followUs: "עקוב אחרינו",
    poweredBy: "מופעל על ידי",
    linksTitle: "קישורים",
    subTotal: "סכום חלקי",
    deliveryFee: "דמי משלוח",
    haveVoucher: "יש לך שובר הנחה?",
    remove: "הסר",
    change: "שנה",
    taxFee: "דמי מס",
    total: "סה''כ",
    tip: "תשלום טיפ",
    discount: "הנחה",
    name: "שם",
    phone: "טלפון",
    email: "אימייל",
    address: "כתובת",
    changeAddress: "שנה כתובת",
    contactInfo: "פרטי קשר",
    paymentMethod: "אמצעי תשלום",
    orderBtn: "הזמן עכשיו",
    deliveryTime: "זמן משלוח",
    delivery: "משלוח",
    searchRestaurantPlaceholder: "חפש מסעדה ומטבחים",
    allRestaurant: "כל המסעדות",
    pending: "ממתין",
    accepted: "התקבל",
    assigned: "הוקצה",
    picked: "נבחר",
    delivered: "נמסר",
    completed: "הושלם",
    titleOrders: "ההזמנות שלי",
    titleProfile: "פרופיל",
    titleSettings: "הגדרות",
    titleHelp: "עזרה",
    titleChat: "צ'אט",
    titleLogout: "התנתקות",
    passwordInfo: "מידע על סיסמה",
    myAddresses: "הכתובות שלי",
    edit: "ערוך",
    creditDebitCard: "כרטיס אשראי / חיוב",
    paypal: "PayPal",
    cash: "מזומן",
    deliverTo: "מסיר ל",
    ASSIGNED: "הוקצה",
    PENDING: "ממתין",
    PICKED: "נבחר",
    ACCEPTED: "התקבל",
    DELIVERED: "נמסר",
    COMPLETED: "הושלם",
    orderPlaced: "הזמנה בוצעה",
    "Order ID": "מספר הזמנה",
    "Order status: PENDING": "סטטוס הזמנה: ממתין",
    "Order status: ACCEPTED": "סטטוס הזמנה: התקבל",
    "Order status: ASSIGNED": "סטטוס הזמנה: הוקצה",
    "Order status: PICKED": "סטטוס הזמנה: נבחר",
    "Order status: DELIVERED": "סטטוס הזמנה: נמסר",
    "Order status: Order Placed": "סטטוס הזמנה: הזמנה בוצעה",
    deliveryAddress: "כתובת למשלוח",
    orderDetail: "פרטי הזמנה",
    orderFrom: "הזמנה מ",
    orderNo: "מספר הזמנה שלך",
    findRestaurants: "מצא מסעדות",
    putUsInYourPocket: "שים אותנו בכיס שלך",
    containerText:
      "כל מה שתרצה - המסעדות שאתה אוהב. מצא אוכל המתאים למצב הרוח שלך, והנח את הנשמה הראשונה. תן לנו, הורד אותנו.",
    findingAddress: "מציאת כתובת באמצעות אינטגרציה של GPS",
    zonesFunctionality: "פונקציונליות אזורים למסעדות ולרוכבים",
    multipleRestaurants: "תכונה להוספת מסעדות מרובות",
    realTimeOrder: "עדכונים בזמן אמת על קבלת הזמנה",
    differentSections: "תכונת מסעדות שונות לקידום",
    previousOrder: "היסטוריית הזמנות קודמות והוספת מסעדות מועדפות",
    ourDelivery: "מערכת ניהול המשלוחים שלנו מיועדת לעתיד.",
    builtOnCommunity: "נבנה על עקרונות של קהילה משתמשת.",
    yourCart: "עגלת הקניות שלך",
    startAdding: "התחל להוסיף פריטים לעגלת הקניות שלך",
    goToCheckout: "לך לקופה",
    activeOrders: "הזמנות פעילות",
    pastOrders: "הזמנות קודמות",
    deliveringTo: "מוסר ל",
    restaurantDeliver: "אנחנו שואלים את המסעדה כמה זמן ייקח להגיע לך את האוכל",
    riderDeliver: "אנחנו שואלים את הרוכב כמה זמן ייקח להגיע לך את האוכל",
    orderPending: "ההזמנה שלך עדיין בהמתנה",
    areYouSure: "האם אתה בטוח?",
    clearCartText: "בעת שינוי המסעדה, הפריטים שהוספת לעגלה יימחקו",
    ok: "אוקיי",
    cancel: "ביטול",
    submit: "שלח",
    titleFavourite: "מועדפים",
    titleEmptyFav: "אין מועדפים שמורים",
    emptyFavDesc:
      "תראה את כל המועדפים שלך כאן, כדי להפוך את ההזמנה למהירה יותר.",
    emptyFavBtn: "בוא נמצא מספר מועדפים",
    exactLocation: "האם זה המיקום המדויק שלך?",
    yourArea: "הזן את האזור שלך",
    currentLocation: "השתמש במיקום הנוכחי",
    deliveryDetails: "פרטי משלוח - לדוגמה, קומה / בניין",
    deliveryDetailError: "פרטי המשלוח נדרשים",
    currentPassword: "סיסמה נוכחית",
    newPassword: "סיסמה חדשה",
    deliver: "מסירה",
    time: "זמן",
    pickup: "איסוף",
    selectTime: "בחר זמן",
    am: "AM",
    pm: "PM",
    set: "קבע",
    apply: "החל",
    applyAVoucher: "החל שובר הנחה",
    readAll: "קרא הכול",
    readMore: "קרא עוד",
    demo: "דמו",
    getQuote: "קבל הצעה",
    restaurantCloseText:
      "המסעדה סגורה כרגע. בדוק אם יש אחרות שפתוחות או תרשם על התפריט כדי לתכנן את הארוחה הבאה שלך.",
    seeOtherRestaurants: "ראה מסעדות אחרות",
    goToMenu: "לך לתפריט",
    deliveryHours: "שעות משלוח",
    about: "אודות",
    reviews: "ביקורות",
    closedAllDay: "סגור לכל היום",
    minute: "דקה",
    welcome: "ברוך הבא!",
    signUpOrLogin: "הירשם או התחבר כדי להמשיך",
    signInWithGoogle: "המשך עם גוגל",
    or: "או",
    continueWithEmail: "המשך עם אימייל",
    terms: "תנאים והגבלות",
    privacyPolicy: "מדיניות פרטיות",
    bySigningUp: "על ידי הרשמה, אתה מסכים ל",
    and: " ו",
    whatsYourEmail: "מה האימייל שלך?",
    checkAccount: "אנחנו נבדוק אם יש לך חשבון",
    continue: "המשך",
    invalidEmail: "אימייל לא תקין",
    letsGetStarted: "בוא נתחיל!",
    createAccount: "צור את חשבון ה-יאללה שלך",
    firstnameErr1: "שם פרטי נדרש!",
    firstnameErr2: "שם פרטי לא תקין!",
    lastnameErr1: "שם משפחה נדרש!",
    lastnameErr2: "שם משפחה לא תקין!",
    mobileErr1: "מספר טלפון נדרש!",
    mobileErr2: "מספר טלפון לא תקין",
    emailErr2: "אימייל לא תקין",
    passwordErr1:
      "סיסמה לא תקינה. הסיסמה חייבת לכלול לפחות אות גדולה אחת, אות קטנה אחת ומספר אחד.",
    passwordErr2: "סיסמה לא תקינה",
    generalErr: "משהו חסר",
    verifyEmail: "אמת את האימייל שלך",
    enterOtp: "אנא הזן את ה-OTP ששלחנו לאימייל המעודכן שלך",
    enterOtpPhone: "אנא הזן את ה-OTP ששלחנו למספר הטלפון המעודכן שלך",
    invalidCode: "קוד לא חוקי, בדוק והזן שוב",
    resendCode: "שלח קוד מחדש",
    retryAfter: "נסה שוב אחרי",
    loginBtn: "התחברות",
    addToCart: "הוסף לעגלה",
    customize: "התאמה אישית",
    optional: "אופציונלי",
    required: "נדרש",
    specialInstructions: "הוראות מיוחדות",
    anySpecific: "יש לך העדפות ספציפיות? יש להודיע למסעדה.",
    selectVariation: "בחר וריאציה",
    closed: "סגור",
    new: "חדש",
    orderCancelled: "ההזמנה שלך בוטלה",
    orderCompleted: "ההזמנה הושלמה בהצלחה. תודה שהזמנת",
    reorder: "הזמן שוב",
    review: "ביקורת",
    subTitle: "בחר 1",
    placeholder: "לדוגמה, ללא מיונז",
    phoneNumMissing: "מספר הטלפון חסר",
    updatePhone: "עדכן את מספר הטלפון שלך",
    number: "מספר?",
    secureAcc: "אנחנו זקוקים לזה לצורך אבטחת החשבון שלך",
    verifyPhone: "אמת את הטלפון שלך",
    skipNow: "דלג כעת",
    favouriteListUpdated: "רשימת המועדפים עודכנה",
    pendingText: "ממתין לתגובה מ",
    acceptedText: "זמן הכנה משוער",
    orderLateText: "סליחה! ההזמנה שלך מעט מאוחרת.",
    riderPickText: "הרוכב שלך יבצע איסוף כאשר ההזמנה תהיה מוכנה",
    orderIs: "ההזמנה שלך",
    orderAssigned: "הוקצתה לרוכב",
    orderAssignedToRider:
      "ההזמנה שלך הוקצתה לרוכב שלנו, ההזמנה תבוצע לאחר שתהיה מוכנה.",
    riderOnWay: "הרוכב שלך בדרך.",
    orderHasBeen: "ההזמנה שלך הושלמה",
    enjoyYourMeal: "תיהנה מהארוחה שלך!",
    cancelled: "בוטל",
    personalDetail: "פרטים אישיים",
    voucherCode: "קוד שובר",
    pickUp: "איסוף",
    warning: "אזהרה",
    warningText: "פריט אחד או יותר אינם זמינים",
    "Rider App": "אפליקצית רוכב",
    FEATURES: "תכונות",
    "Restaurant App": "אפליקציית מסעדה",
    "Customer App": "אפליקציית לקוח",
    "Admin Dashboard": "לוח בקרה למנהל",
    "Product Page": "דף מוצר",
    PlayStore: "חנות Play",
    IosStore: "חנות iOS",
    APP: "יישום",
    Enatega: "Enatega",
    connectText: "חיבור רוכבים, מסעדות ולקוחות למשלוח אוכל חלק",
    connectSubText:
      "הצטרפו לפלטפורמה שמספקת נוחות, גמישות וצמיחה לכל המעורבים. בין אם אתם שליחים המחפשים להרוויח, מסעדה שרוצה להתרחב, או לקוח שחושק בארוחות טעימות – אנחנו כאן בשבילכם!",
    PlatformText: "למה להצטרף לפלטפורמה שלנו?",
    PlatformSubtext:
      "אנחנו לא רק פלטפורמת משלוחי אוכל – אנחנו קהילה שנועדה להועיל לכולם. לכן אלפי שליחים, מסעדות ולקוחות סומכים עלינו:",
    ForRider: "לשליחים:",
    platformRiderText:
      "הרוויחו בלוח הזמנים שלכם, קבלו תשלומים מהירים ותיהנו מכלים קלים לניהול משלוחים.",
    ForRestaurant: "למסעדות:",
    platformRestaurantText:
      "הרחיבו את טווח ההגעה שלכם, הפכו את ניהול ההזמנות לפשוט יותר, והגדילו את ההכנסות עם ניתוחים בזמן אמת.",
    ForCustomer: "ללקוחות:",
    platformCustomerText:
      "תיהנו ממגוון רחב של מסעדות, משלוח מהיר ואפליקציה ואתר ידידותיים למשתמש.",
    orderText: "הזמן את המועדפים שלך בכל זמן, בכל מקום",
    orderSubText1:
      "חווה את הנוחות של having all your favorite restaurant meals and cuisines in one place, delivered straight to your door—fast and fresh. הורד את אפליקציית Enatega היום, והפוך כל חשק למאכל טעים למציאות.",
    orderSubText2:
      "גלה מגוון רחב של אפשרויות, ממנות אהובות מקומיות ואוכל מנחם ועד מנות גורמה, אוכל בריא ועוד.",
    orderSubText3:
      "הורד את אפליקציית Enatega היום, והפוך כל חשק למאכל טעים למציאות.",
    platformFeatureText: "תכונות הפלטפורמה שמבליטות אותנו.",
    platformFeatureSubText:
      "אנו מספקים תכונות מתקדמות כדי להבטיח שהחוויה שלך תהיה חלקה וללא מאמץ:",
    listReal: "מעקב הזמנות בזמן אמת:",
    listRealText: "דע בדיוק היכן האוכל שלך.",
    securePayment: "תשלומים מאובטחים:",
    securePaymentText: "עבור שליחים, מסעדות ולקוחות.",
    twoFour: "תמיכה 24/7:",
    twoFourText: "תמיד כאן כדי לעזור לך.",
    custMenu: "תפריטים מותאמים אישית:",
    custMenuText: "מסעדות יכולות לנהל הצעות בקלות.",
    BecomeRider: "הפוך לשליח והתחל להרוויח היום!",
    earnMore: "הרוויח יותר:",
    earnMoreText: "בצע משלוחים כשנוח לך והרוויח תגמולים מעולים.",
    flexible: "שעות גמישות:",
    flexibleText: "עבוד לפי לוח הזמנים שלך.",
    intsaPay: "תשלומים מיידיים:",
    intsaPayText: "קבל תשלום שבועי או מיידי.",
    growRestuarant: "גדל את המסעדה שלך עם הפלטפורמה שלנו",
    reactCust: "הגיע ליותר לקוחות:",
    reactCustText: "התפרסם בקרב חובבי אוכל מקומיים.",
    easyOrder: "ניהול הזמנות קל:",
    easyOrderText: "נהל הכל במקום אחד.",
    incrRevenue: "הגדלת הכנסות:",
    incrRevenueText: "משוך יותר הזמנות והגדל את העסק שלך.",
    OrderFood: "הזמן אוכל בכל זמן ומקום!",
    expMenu: "חקור תפריטים:",
    expMenuText: "מצא את המטבחים המועדפים עליך.",
    track: "עקוב אחרי הזמנות:",
    trackText: "ראה היכן האוכל שלך נמצא בזמן אמת.",
    mulPlat: "פלטפורמות מרובות:",
    mulPlatText: "הזמן דרך אפליקציית המובייל או האינטרנט.",

    Register: "הרשמה –",
    RegisterText: "רשום את המסעדה שלך בקלות.",
    recieveOrders: "קבל הזמנות –",
    recieveOrdersText: "נהל אותן בקלות עם לוח הבקרה הידידותי למשתמש שלנו.",
    grow: "גדול –",
    growText: "משוך יותר לקוחות והגדל מכירות.",
    signUp: "הירשם –",
    signUpText: "צור פרופיל תוך דקות.",
    accOrders: "קבל הזמנות –",
    accOrdertext: "ספק אוכל בנוחות שלך.",
    earnMoney: "הרוויח כסף –",
    earnMoneyText: "קבל תשלום שבועי או מיידי.",
    brMenu: "עיין בתפריטים –",
    brMenuText: "מצא את האוכל האהוב עליך בקרבתך.",
    placeOrder: "בצע הזמנה –",
    placeOrderText: "תיהנה ממשלוח מהיר.",
    relax: "תרגע –",
    relaxText: "תן לנו לטפל בשאר!",
    contactUs: "צור קשר",
    vendors: "ספקים",
    drivers: "נהגים",
    becomeVendor: "הפוך לספק",
    becomeRestaurant: "הפוך למסעדה",
    getToKnow: "להכיר",
    letUsHelp: "תן לנו לעזור לך",
    howItWorks: "איך זה עובד לכולם",
    registerRestaurant: "רשום את המסעדה שלך",
    signUpRider: "הירשם כרוכב",
    orderFoodNow: "הזמן אוכל עכשיו",
    signupDelivering: "הירשם כדי להתחיל לשלוח",
    riderHeading: "מוכנים כשתהיו מוכנים...",
    riderDescription: "משלוחים טובים יותר. חוויות לקוח טובות יותר.",
    vendorHeading: "פתרון משלוחים שתוכנן לצמיחת המסעדה שלך",
    vendorDescription:
      "הרחב את טווח ההגעה שלך, משוך יותר לקוחות ובנה קשרים חזקים יותר בכל שכונה. הצטרף ל-Enatega כספק היום!",
    contactUsHeading: "איך תרצה לקבל עזרה?",
    contactUsDescription: "בחר את האפשרות הנוחה ביותר עבורך",
    emailConfirmation: "האימייל נשלח בהצלחה!",
    welcomeText: "ברוך הבא ל-אֵנָטֵגָה",
    emailConfirmationDescription:
      "אנו נרגשים לקבל אותך אלינו כשמטרתנו היא לשפר את השירותים בתחום המשלוחים עבור כולם. כשותף משלוחים/איסוף, תוכל לעבוד לפי לוח הזמנים שלך בכל מקום ובכל זמן. זקוק לעזרה? שלח לנו מייל ל-enatega.nb@gmail.com",
    getStarted: "התחל עכשיו",
    thankyouPageHeading: "האימייל שלך נשלח בהצלחה!",
    thankyouPageDescription:
      "חבר מצוותנו ייצור קשר איתך בקרוב כדי לעזור לך בהגדרה ולהשיב על כל שאלה שיש לך. זקוק לעזרה? שלח לנו מייל ל-enatega.nb@gmail.com",
    getStartedToday: "התחל עוד היום!",
    termsRequired: "עליך לקבל את התנאים וההגבלות.",
    passNotMatch: "הסיסמאות אינן תואמות.",
    formSubmission: "הטופס נשלח בהצלחה",
    notFormSubmission: "הטופס לא נשלח",
    firstName: "שם פרטי",
    lastName: "שם משפחה",
    emailAddress: "כתובת אימייל",
    phoneNumber: "הזן את מספר הטלפון שלך",
    password: "סיסמה",
    confirmPassword: "אשר סיסמה",
    viaPhone: "בטלפון",
    vieEmail: 'בדוא"ל',
    viaPhoneDesctiption: "אשר את מספר הטלפון שלך ואנו נתקשר אליך",
    viaEmailDesctiption: "אשר את כתובת האימייל שלך ונשיב לך",
    explainQuery: "הסבר בקצרה את שאלתך...",
    message: "הודעה",
    enterValidEmail: "הזן כתובת אימייל תקינה",
    requiredEmail: "נדרש אימייל",
    enterValidPhone: "הזן מספר טלפון תקין",
    'I agree to the': ' אני מסכים ל',
  },
};
export default he;
