const fr = {
  translation: {
    selectLanguage: "Choisir la langue",
    saveButton: "sauvegarder",
    footerText:
      "Enatega est une plateforme open source de gestion des livraisons pour le avenir. Nous accordons la priorité à l innovation, à la flexibilité et à l abordabilité, et offrez une solution évolutive et personnalisable qui rationalise votre processus de livraison.",
    footerLinkHome: "Accueil",
    footerLinkPP: "Politique de confidentialité",
    footerLinkTC: "Conditions générales",
    footerEndText: "Enatega - © 2022 Tous droits réservés",
    followUs: "Suivez-nous",
    poweredBy: "Propulsé par",
    linksTitle: "Liens",
    subTotal: "Total",
    deliveryFee: "Frais de livraison",
    haveVoucher: "Avez-vous un bon",
    remove: "Retirer",
    change: "Changement",
    taxFee: "charges fiscales",
    total: "Total",
    tip: "Pourboire",
    discount: "Remise",
    name: "prénom",
    phone: "Téléphone",
    email: "Email",
    address: "Adresse",
    changeAddress: "Changer l adresse",
    contactInfo: "Informations de contact",
    paymentMethod: "Mode de paiement",
    orderBtn: "Passer la commande",
    deliveryTime: "Heure de livraison",
    delivery: "Livraison",
    searchRestaurantPlaceholder: "Recherche de restaurants et de cuisines",
    allRestaurant: "Tous les restaurants",
    pending: "En attente",
    accepted: "Accepté(e)",
    assigned: "Assigné(e)",
    picked: "Ramassé(e)",
    delivered: "Livré(e)",
    completed: "Terminé(e)",
    titleOrders: "Mes commandes",
    titleProfile: "Profil",
    titleHelp: "Aidez-moi",
    titleSettings: "Setting",
    titleChat: "Bavarder",
    titleLogout: "Connectez - Out",
    passwordInfo: "Informations de mot de passe",
    myAddresses: "Mes adresses",
    edit: "Modifier",
    creditDebitCard: "Carte de crédit / débit",
    paypal: "Paypal",
    cash: "Espèces / Liquide",
    deliverTo: "Livrer à",
    ASSIGNED: "attribué",
    PENDING: "en attente",
    PICKED: "choisi",
    ACCEPTED: "accepté",
    DELIVERED: "livré",
    COMPLETED: "terminé",
    orderPlaced: "Commande passée",
    "Order ID": "Numéro de commande",
    "Order status: PENDING": "Suivi de commande: En attente",
    "Order status: ACCEPTED": "Statut de la commande : ACCEPTÉ",
    "Order status: ASSIGNED": "Statut de la commande : ATTRIBUÉ",
    "Order status: PICKED": "Statut de la commande : CHOISI",
    "Order status: DELIVERED": "Statut de la commande : LIVRÉ",
    "Order status: Order Placed": "Statut de la commande : Commande passée",
    deliveryAddress: "Adresse de livraison",
    orderDetail: "Détails de la commande",
    orderFrom: "Votre commande de",
    orderNo: "Votre numéro de commande",
    findRestaurants: "Trouver des restaurants",
    putUsInYourPocket: "Mettez-nous dans votre poche",
    containerText:
      "Tout est à portée de main - les restaurants que vous adorez. Trouvez la nourriture idéale pour satisfaire vos envies et faites durer le plaisir dès la première bouchée. Allez-y, téléchargez-nous.",
    findingAddress: "Trouver une adresse en utilisant l'intégration GPS",
    zonesFunctionality:
      "Fonctionnalité de zones pour les restaurants et les livreurs",
    multipleRestaurants: "Fonctionnalité d'ajout de plusieurs restaurants",
    realTimeOrder: "Mises à jour en temps réel de la réception des commandes",
    differentSections:
      "Fonctionnalité de différentes sections pour promouvoir les restaurants",
    previousOrder:
      "Historique des commandes précédentes et ajout de restaurants favoris",
    ourDelivery:
      "Notre système de gestion des livraisons est conçu pour l'avenir",
    builtOnCommunity: "Basé sur des principes communautaires",
    yourCart: "Votre panier",
    startAdding: "Commencez à ajouter des articles à votre panier",
    goToCheckout: "ALLER À LA CAISSE",
    activeOrders: "Commandes en cours",
    pastOrders: "Commandes passées",
    deliveringTo: "Livraison à",
    restaurantDeliver:
      "Nous demandons au restaurant combien de temps il faudra pour livrer votre nourriture.",
    riderDeliver:
      "Nous demandons au livreur combien de temps il faudra pour livrer votre nourriture",
    orderPending: "Votre commande est toujours en attente",
    areYouSure: "Êtes-vous sûr ?",
    clearCartText:
      "En changeant de restaurant, les articles que vous avez ajoutés au panier seront supprimés",
    ok: "OK",
    cancel: "ANNULER",
    submit: "Soumettre",
    titleFavourite: "favorite",
    titleEmptyFav: "Aucun favori enregistré",
    emptyFavDesc:
      "Vous verrez tous vos favoris ici, pour rendre la commande encore plus rapide.",
    emptyFavBtn: "Trouvons quelques favoris",
    exactLocation: "Est-ce votre emplacement exact ?",
    yourArea: "Entrez votre région",
    currentLocation: "Utiliser l'emplacement actuel",
    deliveryDetails: "Détails requis -e.g. étage / bâtiment",
    deliveryDetailError: "Les détails de livraison sont requis",
    currentPassword: "Mot de passe actuel",
    newPassword: "Nouveau mot de passe",
    deliver: "Livraison",
    time: "Temps",
    pickup: "Ramassage",
    selectTime: "Sélectionner l'heure",
    am: "AM",
    pm: "PM",
    set: "Définir",
    apply: "Appliquer",
    applyAVoucher: "Appliquer un bon",
    readAll: "Tout lire",
    readMore: "Lire plus",
    demo: "Démo",
    getQuote: "Obtenir un devis",
    restaurantCloseText:
      "Le restaurant est actuellement fermé. Découvrez les autres restaurants ou consultez le menu pour planifier votre prochain repas.",
    seeOtherRestaurants: "VOIR LES AUTRES RESTAURANTS",
    goToMenu: "Aller au menu",
    deliveryHours: "Heures de livraison",
    about: "À PROPOS DE",
    reviews: "AVIS",
    closedAllDay: "Fermé toute la journée",
    minute: "Minute",
    welcome: "Bienvenue !",
    signUpOrLogin: "Inscrivez-vous ou connectez-vous pour continuer",
    signInWithGoogle: "CONTINUER AVEC GOOGLE",
    or: "ou",
    continueWithEmail: "CONTINUER AVEC EMAIL",
    terms: "Conditions générales",
    privacyPolicy: "Politique de confidentialité",
    bySigningUp: "En vous inscrivant, vous acceptez notre ",
    and: " et ",
    whatsYourEmail: "Quelle est votre adresse e-mail ?",
    checkAccount: "Nous vérifierons si vous avez un compte",
    continue: "Continuer",
    invalidEmail: "Adresse e-mail invalide",
    letsGetStarted: "Commençons !",
    createAccount: "Créez votre compte Enatega",
    firstnameErr1: "Le prénom est requis !",
    firstnameErr2: "Prénom invalide !",
    lastnameErr1: "Le nom de famille est requis !",
    lastnameErr2: "Nom de famille invalide !",
    mobileErr1: "Le numéro de téléphone est requis !",
    mobileErr2: "Numéro de téléphone invalide !",
    emailErr2: "E-mail invalide",
    passwordErr1:
      "Mot de passe invalide. Le mot de passe doit contenir au moins 1 lettre majuscule, 1 lettre minuscule et 1 chiffre.",
    passwordErr2: "Mot de passe invalide",
    generalErr: "Quelque chose manque",
    verifyEmail: "Vérifiez votre e-mail",
    enterOtp:
      "Veuillez entrer le code OTP que nous avons envoyé à votre e-mail mis à jour",
    enterOtpPhone:
      "Veuillez entrer le code OTP que nous avons envoyé à votre numéro de numéro mis à jour",
    invalidCode: "Code invalide, veuillez vérifier et entrer à nouveau",
    resendCode: "Renvoyer le code",
    retryAfter: "Réessayer après",
    loginBtn: "S'identifier",
    addToCart: "Ajouter au panier",
    customize: "Personnaliser",
    optional: "Facultatif",
    required: "Obligatoire",
    specialInstructions: "Instructions spéciales",
    anySpecific:
      "Des préférences spécifiques ? Faites-le savoir au restaurant.",
    selectVariation: "Sélectionner la variation",
    closed: "Fermé",
    new: "NOUVEAU",
    orderCancelled: "Votre commande a été annulée",
    orderCompleted:
      "Commande complétée avec succès. Merci d'avoir passé commande",
    reorder: "RECOMMANDER",
    review: "Critique",
    subTitle: "Sélectionner 1",
    placeholder: "par exemple, sans mayonnaise",
    phoneNumMissing: "Numéro de téléphone manquant",
    updatePhone: "Mettez à jour votre téléphone",
    number: "numéro ?",
    secureAcc: "Nous avons besoin de ceci pour sécuriser votre compte",
    verifyPhone: "Vérifiez votre téléphone",
    skipNow: "Passer maintenant",
    favouriteListUpdated: "Liste de favoris mise à jour",
    pendingText: "En attente d'une réponse de",
    acceptedText: "Temps de préparation estimé",
    orderLateText: "Désolé ! Votre commande est un peu en retard.",
    riderPickText: "Votre livreur la récupérera une fois prête.",
    orderIs: "Votre commande est",
    orderAssigned: "Assignée au livreur",
    orderAssignedToRider:
      "Votre commande a été assignée à notre livreur, elle sera récupérée une fois prête.",
    riderOnWay: "Votre livreur est en chemin.",
    orderHasBeen: "Votre commande a été",
    enjoyYourMeal: "Bon appétit !",
    cancelled: "Annulée",
    personalDetail: "Détail personnel",
    voucherCode: "Code de réduction",
    pickUp: "Ramassage",
    warning: "Avertissement",
    warningText: "Un ou plusieurs éléments ne sont pas disponibles",
    Enatega: "Enatega",
    connectText:
      "Connecter les livreurs, les restaurants et les clients pour une livraison de nourriture sans accroc",
    connectSubText:
      "Rejoignez la plateforme qui offre commodité, flexibilité et croissance à tous. Que vous soyez un livreur cherchant à gagner, un restaurant visant à se développer ou un client en quête de délicieux repas, nous sommes là pour vous !",
    platformFeatureText:
      "Fonctionnalités de la plateforme qui nous distinguent.",
    platformFeatureSubText:
      "Nous proposons des fonctionnalités de pointe pour garantir que votre expérience soit fluide et sans tracas :",
    listReal: "Suivi des commandes en temps réel :",
    listRealText: "Sachez exactement où se trouve votre nourriture.",
    securePayment: "Paiements sécurisés :",
    securePaymentText: "Pour les livreurs, les restaurants et les clients.",
    twoFour: "Support 24/7 :",
    twoFourText: "Toujours là pour vous aider.",
    custMenu: "Menus personnalisables :",
    custMenuText: "Les restaurants peuvent gérer leurs offres sans effort.",
    BecomeRider: "Devenez livreur et commencez à gagner dès aujourd'hui !",
    earnMore: "Gagnez plus :",
    earnMoreText: "Livrez quand vous voulez et gagnez de bons revenus.",
    flexible: "Horaires flexibles :",
    flexibleText: "Travaillez selon votre emploi du temps.",
    intsaPay: "Paiements instantanés :",
    intsaPayText: "Recevez un paiement hebdomadaire ou instantané.",
    growRestuarant: "Faites grandir votre restaurant avec notre plateforme",
    reactCust: "Touchez plus de clients :",
    reactCustText: "Soyez découvert par les amateurs de nourriture locale.",
    easyOrder: "Gestion facile des commandes :",
    easyOrderText: "Gérez tout depuis un seul tableau de bord.",
    incrRevenue: "Augmentez vos revenus :",
    incrRevenueText:
      "Attirez plus de commandes et développez votre entreprise.",
    OrderFood: "Commandez de la nourriture à tout moment et n'importe où !",
    expMenu: "Explorez les menus :",
    expMenuText: "Trouvez vos cuisines préférées.",
    track: "Suivi des commandes :",
    trackText: "Voyez où se trouve votre nourriture en temps réel.",
    mulPlat: "Plateformes multiples :",
    mulPlatText: "Commandez via l'application mobile ou l'application web.",

    Register: "Inscrivez-vous –",
    RegisterText: "Ajoutez facilement votre restaurant.",
    recieveOrders: "Recevez des commandes –",
    recieveOrdersText: "Gérez-les avec notre tableau de bord convivial.",
    grow: "Développez-vous –",
    growText: "Attirez plus de clients et augmentez vos ventes.",
    SignUp: "Inscription –",
    signUpText: "Créez un profil en quelques minutes.",
    accOrders: "Acceptez les commandes –",
    accOrdertext: "Livrez la nourriture selon votre convenance.",
    earnMoney: "Gagnez de l'argent –",
    earnMoneyText: "Recevez un paiement hebdomadaire ou instantané.",
    brMenu: "Explorez les menus –",
    brMenuText: "Trouvez votre plat préféré à proximité.",
    placeOrder: "Passez une commande –",
    placeOrderText: "Profitez d'une livraison rapide.",
    relax: "Détendez-vous –",
    relaxText: "Laissez-nous nous occuper du reste !",
    contactUs: "Contactez-nous",
    vendors: "Vendeurs",
    drivers: "Chauffeurs",
    becomeVendor: "Devenez un vendeur",
    becomeRestaurant: "Devenez un restaurant",
    getToKnow: "Apprenez à nous connaître",
    letUsHelp: "Laissez-nous vous aider",
    howItWorks: "Comment ça marche pour tout le monde",
    registerRestaurant: "Enregistrez votre restaurant",
    signUpRider: "Inscrivez-vous en tant que livreur",
    orderFoodNow: "Commandez de la nourriture maintenant",
    signupDelivering: "Inscrivez-vous pour commencer à livrer",
    riderHeading: "Prêt quand vous l’êtes...",
    riderDescription:
      "De meilleures livraisons. De meilleures expériences clients.",
    vendorHeading:
      "Une solution de livraison conçue pour développer votre restaurant",
    vendorDescription:
      "Étendez votre portée, attirez plus de clients et créez des liens plus forts dans chaque quartier. Rejoignez Enatega en tant que vendeur dès aujourd’hui!",
    contactUsHeading: "Comment souhaitez-vous obtenir de l’aide?",
    contactUsDescription: "Choisissez l’option qui vous convient",
    emailConfirmation: "E-mail envoyé avec succès!",
    welcomeText: "Bienvenue sur Enatega",
    emailConfirmationDescription:
      "Nous sommes ravis de vous accueillir parmi nous alors que nous visons à améliorer la livraison pour tout le monde. En tant que partenaire de livraison/ramassage, vous pouvez travailler selon votre emploi du temps, n’importe où, n’importe quand. Besoin d’aide ? Envoyez-nous un e-mail à enatega.nb@gmail.com",
    getStarted: "Commencer",
    thankyouPageHeading: "Votre e-mail a été envoyé avec succès!",
    thankyouPageDescription:
      "Un membre de notre équipe vous contactera sous peu pour vous aider à configurer votre compte et répondre à toutes vos questions. Besoin d’aide ? Envoyez-nous un e-mail à enatega.nb@gmail.com",
    getStartedToday: "Commencez dès aujourd’hui!",
    termsRequired: "Vous devez accepter les termes et conditions.",
    passNotMatch: "Les mots de passe ne correspondent pas.",
    formSubmission: "Formulaire soumis avec succès",
    notFormSubmission: "Formulaire non soumis",
    firstName: "Prénom",
    lastName: "Nom de famille",
    emailAddress: "Adresse e-mail",
    phoneNumber: "Entrez votre numéro de téléphone",
    password: "Mot de passe",
    confirmPassword: "Confirmez le mot de passe",
    viaPhone: "Par téléphone",
    vieEmail: "Par e-mail",
    viaPhoneDesctiption:
      "Confirmez votre numéro de téléphone et nous vous appellerons",
    viaEmailDesctiption:
      "Confirmez votre adresse e-mail et nous vous répondrons",
    explainQuery: "Expliquez brièvement votre demande...",
    message: "Message",
    enterValidEmail: "Entrez une adresse e-mail valide",
    requiredEmail: "L'e-mail est requis",
    enterValidPhone: "Entrez un numéro de téléphone valide",
    'I agree to the': "Je suis d accord avec ",
  },
};
export default fr;
