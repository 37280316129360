const de = {
  translation: {
    selectLanguage: "Sprache auswählen",
    saveButton: "speichern",
    footerText:
      "enatega ist eine Open-Source-Liefermanagementplattform für Zukunft. Wir legen Wert auf Innovation, Flexibilität und Erschwinglichkeit. und bieten Sie eine skalierbare, anpassbare Lösung, die Ihre Prozesse optimiert Lieferprozesse.",
    footerLinkHome: "Startseite",
    footerLinkPP: "Datenschutzbestimmungen",
    footerLinkTC: "Geschäftsbedingungen",
    footerEndText: "enatega - © 2022 Alle Rechte vorbehalten",
    followUs: "Folge uns",
    poweredBy: "Angetrieben von",
    linksTitle: "Verknüpfungen",
    subTotal: "Zwischensumme",
    deliveryFee: "Liefergebühr",
    haveVoucher: "Hast du einen Gutschein?",
    remove: "Entfernen",
    change: "Veränderung",
    taxFee: "Steuerbelastungen",
    total: "Gesamt",
    tip: "Tipp",
    discount: "Rabatt",
    name: "Name",
    phone: "Telefon",
    email: "Email",
    address: "Adresse",
    changeAddress: "Adresse ändern",
    contactInfo: "Kontaktinformationen",
    paymentMethod: "Zahlungsmethode",
    orderBtn: "Bestellung aufgeben",
    deliveryTime: "Lieferzeit",
    delivery: "Lieferung",
    searchRestaurantPlaceholder: "Suche nach Restaurants und Küchen",
    allRestaurant: "Alle Restaurants",
    pending: "Ausstehend",
    accepted: "Akzeptiert",
    assigned: "Zugewiesen",
    picked: "Abgeholt",
    delivered: "Geliefert",
    completed: "Abgeschlossen",
    titleOrders: "meine Bestellungen",
    titleSettings: "Einstellungen",
    titleProfile: "Profiel",
    titleHelp: "Hilfe",
    titleChat: "Plaudern",
    titleLogout: "Ausloggen",
    passwordInfo: "Passwortinformationen",
    myAddresses: "Meine Adressen",
    edit: "Bearbeiten",
    creditDebitCard: "Kredit- / Debitkarte",
    paypal: "Paypal",
    cash: "Bargeld",
    deliverTo: "Liefern an",
    ASSIGNED: "zugewiesen",
    PENDING: "ausstehend",
    PICKED: "abgeholt",
    ACCEPTED: "akzeptiert",
    DELIVERED: "geliefert",
    COMPLETED: "abgeschlossen",
    orderPlaced: "Bestellung aufgegeben",
    "Order ID": "Auftragsnummer",
    "Order status: PENDING": "Bestellstatus: AUSSTEHEND",
    "Order status: ACCEPTED": "Bestellstatus: AKZEPTIERT",
    "Order status: ASSIGNED": "Bestellstatus: ZUGEWIESEN",
    "Order status: PICKED": "Bestellstatus: AUSGEWÄHLT",
    "Order status: DELIVERED": "Bestellstatus: GELIEFERT",
    "Order status: Order Placed": "Bestellstatus: Bestellung aufgegeben",
    deliveryAddress: "Lieferadresse",
    orderDetail: "Bestelldetails",
    orderFrom: "Ihre Bestellung von",
    orderNo: "Ihre Bestellnummer",
    findRestaurants: "Restaurants finden",
    putUsInYourPocket: "Steck uns in deine Tasche.",
    containerText:
      "Alles liegt in deiner Handfläche – die Restaurants, die du liebst. Finde das richtige Essen, um deine Stimmung zu treffen, und mache den ersten Bissen zum Erlebnis. Los, lade uns herunter.",
    findingAddress: "Adresse finden mit GPS-Integration",
    zonesFunctionality: "Zonenfunktion für Restaurants und Fahrer",
    multipleRestaurants: "Funktion zum Hinzufügen mehrerer Restaurants",
    realTimeOrder: "Echtzeitaktualisierungen zum Bestellempfang",
    differentSections:
      "Funktion für verschiedene Abschnitte zur Förderung von Restaurants",
    previousOrder:
      "Vorherige Bestellhistorie und Hinzufügen von Lieblingsrestaurants",
    ourDelivery: "Unser Liefersystem ist für die Zukunft konzipiert",
    builtOnCommunity: "Auf gemeinschaftsgetriebenen Prinzipien aufgebaut",
    yourCart: "Ihr Warenkorb",
    startAdding: "Beginnen Sie damit, Artikel in Ihren Warenkorb zu legen",
    goToCheckout: "ZUR KASSE GEHEN",
    activeOrders: "Aktive Bestellungen",
    pastOrders: "Vergangene Bestellungen",
    deliveringTo: "Lieferung an",
    restaurantDeliver:
      "Wir fragen das Restaurant, wie lange es dauern wird, um Ihr Essen zu liefern",
    riderDeliver:
      "Wir fragen den Fahrer, wie lange es dauern wird, um Ihr Essen zu liefern",
    orderPending: "Ihre Bestellung ist noch ausstehend",
    areYouSure: "Bist du sicher?",
    clearCartText:
      "Durch den Wechsel des Restaurants werden die Artikel in deinem Warenkorb gelöscht",
    ok: "OK",
    cancel: "Abbrechen",
    submit: "einreichen",
    titleFavourite: "Favoritin",
    titleEmptyFav: "Keine Favoriten gespeichert",
    emptyFavDesc:
      "Hier sehen Sie alle Ihre Favoriten, um die Bestellung noch schneller zu machen.",
    emptyFavBtn: "Lassen Sie uns einige Favoriten finden",
    exactLocation: "Ist dies dein genauer Standort?",
    yourArea: "Gib deine Region ein",
    currentLocation: "Aktuellen Standort verwenden",
    deliveryDetails: "Erforderliche Angaben, z. Boden / Gebäude",
    deliveryDetailError: "Lieferdetails sind erforderlich",
    currentPassword: "Aktuelles Passwort",
    newPassword: "Neues Passwort",
    deliver: "Lieferung",
    time: "Zeit",
    pickup: "Abholung",
    selectTime: "Zeit auswählen",
    am: "vormittags",
    pm: "nachmittags",
    set: "Einstellen",
    apply: "Anwenden",
    applyAVoucher: "Gutschein anwenden",
    readAll: "Alles lesen",
    readMore: "Mehr lesen",
    demo: "Demo",
    getQuote: "Angebot erhalten",
    restaurantCloseText:
      "Das Restaurant ist derzeit geschlossen. Schauen Sie sich andere offene Restaurants an oder werfen Sie einen Blick auf die Speisekarte für Ihre nächste Mahlzeit.",
    seeOtherRestaurants: "ANDERE RESTAURANTS ANZEIGEN",
    goToMenu: "Zur Speisekarte",
    deliveryHours: "Lieferzeiten",
    about: "ÜBER",
    reviews: "BEWERTUNGEN",
    closedAllDay: "Den ganzen Tag geschlossen",
    minute: "Minute",
    welcome: "Willkommen!",
    signUpOrLogin: "Registrieren oder anmelden, um fortzufahren",
    signInWithGoogle: "MIT GOOGLE FORTFAHREN",
    or: "oder",
    continueWithEmail: "MIT E-MAIL FORTFAHREN",
    terms: "Allgemeine Geschäftsbedingungen",
    privacyPolicy: "Datenschutzrichtlinie",
    bySigningUp: "Durch die Registrierung stimmst du unseren ",
    and: " und ",
    whatsYourEmail: "Was ist deine E-Mail-Adresse?",
    checkAccount: "Wir überprüfen, ob du ein Konto hast",
    continue: "Weiter",
    invalidEmail: "Ungültige E-Mail-Adresse",
    letsGetStarted: "Lass uns anfangen!",
    createAccount: "Erstelle dein enatega-Konto",
    firstnameErr1: "Vorname ist erforderlich!",
    firstnameErr2: "Ungültiger Vorname!",
    lastnameErr1: "Nachname ist erforderlich!",
    lastnameErr2: "Ungültiger Nachname!",
    mobileErr1: "Telefonnummer ist erforderlich!",
    mobileErr2: "Ungültige Telefonnummer!",
    emailErr2: "Ungültige E-Mail-Adresse",
    passwordErr1:
      "Ungültiges Passwort. Das Passwort muss mindestens 1 Großbuchstaben, 1 Kleinbuchstaben und 1 Zahl enthalten.",
    passwordErr2: "Ungültiges Passwort",
    generalErr: "Etwas fehlt",
    verifyEmail: "Bestätigen Sie Ihre E-Mail",
    enterOtp:
      "Bitte geben Sie den OTP ein, den wir an Ihre aktualisierte E-Mail gesendet haben",
    enterOtpPhone:
      "Bitte geben Sie den OTP ein, den wir an Ihre aktualisierte Telefonnummer gesendet haben",
    invalidCode: "Ungültiger Code, bitte überprüfen und erneut eingeben",
    resendCode: "Code erneut senden",
    retryAfter: "Erneuter Versuch nach",
    loginBtn: "Anmeldung",
    addToCart: "in den Warenkorb legen",
    customize: "Anpassen",
    optional: "Optional",
    required: "Erforderlich",
    specialInstructions: "Besondere Anweisungen",
    anySpecific:
      "Irgendwelche spezifischen Vorlieben? Lassen Sie es das Restaurant wissen.",
    selectVariation: "Variation auswählen",
    closed: "Geschlossen",
    new: "NEU",
    orderCancelled: "Ihre Bestellung wurde storniert",
    orderCompleted:
      "Bestellung erfolgreich abgeschlossen. Vielen Dank für Ihre Bestellung",
    reorder: "NEUBESTELLEN",
    review: "Überprüfung",
    subTitle: "Auswahl 1",
    placeholder: "z.B. Keine Mayo",
    phoneNumMissing: "Telefonnummer fehlt",
    updatePhone: "Aktualisieren Sie Ihr Telefon",
    number: "Nummer?",
    secureAcc: "Wir brauchen das, um Ihr Konto zu sichern",
    verifyPhone: "Überprüfen Sie Ihr Telefon",
    skipNow: "Jetzt überspringen",
    favouriteListUpdated: "Favoritenliste aktualisiert",
    pendingText: "Warten auf Antwort von",
    acceptedText: "Geschätzte Zubereitungszeit",
    orderLateText: "Entschuldigung! Ihre Bestellung kommt etwas spät.",
    riderPickText: "Ihr Fahrer wird es abholen, sobald es bereit ist.",
    orderIs: "Ihre Bestellung ist",
    orderAssigned: "Dem Fahrer zugewiesen",
    orderAssignedToRider:
      "Ihre Bestellung wurde unserem Fahrer zugewiesen und wird abgeholt, sobald sie bereit ist.",
    riderOnWay: "Ihr Fahrer ist unterwegs.",
    orderHasBeen: "Ihre Bestellung wurde",
    enjoyYourMeal: "Guten Appetit!",
    cancelled: "Abgebrochen",
    personalDetail: "Persönliche Angaben",
    voucherCode: "Gutscheincode",
    pickUp: "Abholung",
    warning: "Warnung",
    warningText: "Ein oder mehrere Elemente sind nicht verfügbar",
    Enatega: "Enatega",
    connectText:
      "Verbindung von Fahrern, Restaurants und Kunden für eine nahtlose Essenslieferung",
    connectSubText:
      "Treten Sie der Plattform bei, die Komfort, Flexibilität und Wachstum für alle Beteiligten bietet. Egal, ob Sie ein Fahrer sind, der Geld verdienen möchte, ein Restaurant, das expandieren möchte, oder ein Kunde, der köstliche Mahlzeiten sucht – wir haben, was Sie brauchen!",
    platformFeatureText: "Plattformfunktionen, die uns auszeichnen.",
    platformFeatureSubText:
      "Wir bieten fortschrittliche Funktionen, um sicherzustellen, dass Ihre Erfahrung reibungslos und stressfrei ist:",
    listReal: "Echtzeit-Bestellverfolgung:",
    listRealText: "Erfahren Sie genau, wo Ihr Essen ist.",
    securePayment: "Sichere Zahlungen:",
    securePaymentText: "Für Fahrer, Restaurants und Kunden.",
    twoFour: "24/7-Support:",
    twoFourText: "Immer hier, um Ihnen zu helfen.",
    custMenu: "Anpassbare Menüs:",
    custMenuText: "Restaurants können ihre Angebote mühelos verwalten.",
    BecomeRider: "Werde Fahrer und beginne heute zu verdienen!",
    earnMore: "Verdiene mehr:",
    earnMoreText:
      "Liefern Sie, wann Sie möchten, und verdienen Sie großartige Auszahlungen.",
    flexible: "Flexible Arbeitszeiten:",
    flexibleText: "Arbeiten Sie nach Ihrem eigenen Zeitplan.",
    intsaPay: "Sofortige Zahlungen:",
    intsaPayText: "Werden Sie wöchentlich oder sofort bezahlt.",
    growRestuarant: "Steigern Sie Ihr Restaurant mit unserer Plattform",
    reactCust: "Erreichen Sie mehr Kunden:",
    reactCustText: "Werden Sie von lokalen Feinschmeckern entdeckt.",
    easyOrder: "Einfache Bestellverwaltung:",
    easyOrderText: "Verwalten Sie alles in einem Dashboard.",
    incrRevenue: "Einnahmen steigern:",
    incrRevenueText:
      "Ziehen Sie mehr Bestellungen an und erweitern Sie Ihr Geschäft.",
    OrderFood: "Bestellen Sie Essen jederzeit und überall!",
    expMenu: "Menüs durchsuchen:",
    expMenuText: "Finden Sie Ihre Lieblingsküchen.",
    track: "Bestellungen verfolgen:",
    trackText: "Sehen Sie, wo sich Ihr Essen in Echtzeit befindet.",
    mulPlat: "Mehrere Plattformen:",
    mulPlatText: "Bestellen Sie über die mobile App oder die Web-App.",

    Register: "Registrieren –",
    RegisterText: "Fügen Sie Ihr Restaurant einfach hinzu.",
    recieveOrders: "Bestellungen erhalten –",
    recieveOrdersText:
      "Verwalten Sie diese mit unserem benutzerfreundlichen Dashboard.",
    grow: "Wachsen –",
    growText: "Ziehen Sie mehr Kunden an und steigern Sie den Umsatz.",
    SignUp: "Anmelden –",
    signUpText: "Erstellen Sie ein Profil in wenigen Minuten.",
    accOrders: "Bestellungen akzeptieren –",
    accOrdertext: "Liefern Sie Essen, wann es Ihnen passt.",
    earnMoney: "Geld verdienen –",
    earnMoneyText: "Erhalten Sie wöchentliche oder sofortige Zahlungen.",
    brMenu: "Menüs durchsuchen –",
    brMenuText: "Finden Sie Ihr Lieblingsessen in der Nähe.",
    placeOrder: "Bestellung aufgeben –",
    placeOrderText: "Genießen Sie eine schnelle Lieferung.",
    relax: "Entspannen –",
    relaxText: "Wir kümmern uns um den Rest!",
    contactUs: "Kontaktieren Sie uns",
    vendors: "Anbieter",
    drivers: "Fahrer",
    becomeVendor: "Werde ein Anbieter",
    becomeRestaurant: "Werde ein Restaurant",
    getToKnow: "Lernen Sie uns kennen",
    letUsHelp: "Lassen Sie uns Ihnen helfen",
    howItWorks: "Wie es für alle funktioniert",
    registerRestaurant: "Registrieren Sie Ihr Restaurant",
    signUpRider: "Melden Sie sich als Fahrer an",
    orderFoodNow: "Essen jetzt bestellen",
    signupDelivering: "Melden Sie sich an, um mit der Lieferung zu beginnen",
    riderHeading: "Bereit, wenn du es bist...",
    riderDescription: "Bessere Lieferungen. Bessere Kundenerfahrungen.",
    vendorHeading:
      "Eine Lieferlösung, die darauf ausgelegt ist, Ihr Restaurant wachsen zu lassen",
    vendorDescription:
      "Erweitern Sie Ihre Reichweite, gewinnen Sie mehr Kunden und bauen Sie stärkere Verbindungen in jedem Viertel auf. Werden Sie noch heute ein Enatega-Anbieter!",
    contactUsHeading: "Wie möchten Sie Hilfe erhalten?",
    contactUsDescription:
      "Wählen Sie die Option, die für Sie am bequemsten ist",
    emailConfirmation: "E-Mail erfolgreich gesendet!",
    welcomeText: "Willkommen bei Enatega",
    emailConfirmationDescription:
      "Wir freuen uns, dass Sie sich uns anschließen, während wir darauf abzielen, den Lieferservice für alle zu verbessern. Als Liefer-/Abholpartner können Sie nach Ihrem eigenen Zeitplan überall und jederzeit arbeiten. Brauchen Sie Hilfe? Senden Sie uns eine E-Mail an enatega.nb@gmail.com",
    getStarted: "Jetzt loslegen",
    thankyouPageHeading: "Ihre E-Mail wurde erfolgreich gesendet!",
    thankyouPageDescription:
      "Ein Mitglied unseres Teams wird sich in Kürze bei Ihnen melden, um Ihnen bei der Einrichtung zu helfen und alle Fragen zu beantworten, die Sie haben könnten. Brauchen Sie Hilfe? Senden Sie uns eine E-Mail an enatega.nb@gmail.com",
    getStartedToday: "Fangen Sie noch heute an!",
    termsRequired:
      "Sie müssen die Allgemeinen Geschäftsbedingungen akzeptieren.",
    passNotMatch: "Passwörter stimmen nicht überein.",
    formSubmission: "Formular erfolgreich eingereicht",
    notFormSubmission: "Formular nicht eingereicht",
    firstName: "Vorname",
    lastName: "Nachname",
    emailAddress: "E-Mail-Adresse",
    phoneNumber: "Geben Sie Ihre Telefonnummer ein",
    password: "Passwort",
    confirmPassword: "Passwort bestätigen",
    viaPhone: "Per Telefon",
    vieEmail: "Per E-Mail",
    viaPhoneDesctiption:
      "Bestätigen Sie Ihre Telefonnummer und wir rufen Sie an",
    viaEmailDesctiption:
      "Bestätigen Sie Ihre E-Mail-Adresse und wir antworten Ihnen",
    explainQuery: "Erklären Sie Ihre Anfrage kurz...",
    message: "Nachricht",
    enterValidEmail: "Geben Sie eine gültige E-Mail-Adresse ein",
    requiredEmail: "E-Mail ist erforderlich",
    enterValidPhone: "Geben Sie eine gültige Telefonnummer ein",
    'I agree to the': "Ich stimme zu ",
  },
};
export default de;
